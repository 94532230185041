import * as actionTypes from './types'
import axiosZapier from 'utils/axiosZapier'
import axiosWorkflow from 'utils/axiosWorkflow'


export const postDocumentLinkingRequest = () => {
    return {
        type: actionTypes.POST_DOCUMENT_LINKING_REQUEST,
    };
};

export const postDocumentLinkingSuccess = (res) => {
    return {
        type: actionTypes.POST_DOCUMENT_LINKING_SUCCESS,
        res,
    };
};

export const postDocumentLinkingFail = (error) => {
    return {
        type: actionTypes.POST_DOCUMENT_LINKING_FAIL,
        error: error
    };
};


export const getDocumentLinkingRequest = () => {
    return {
        type: actionTypes.GET_DOCUMENT_LINKING_REQUEST,
    };
};

export const getDocumentLinkingSuccess = (res) => {
    return {
        type: actionTypes.GET_DOCUMENT_LINKING_SUCCESS,
        res,
    };
};

export const getDocumentLinkingFail = (error) => {
    return {
        type: actionTypes.GET_DOCUMENT_LINKING_FAIL,
        error: error
    };
};

export const postTextImagingRequest = () => {
    return {
        type: actionTypes.POST_TEXT_IMAGING_REQUEST,
    };
};

export const postTextImagingSuccess = (res) => {
    return {
        type: actionTypes.POST_TEXT_IMAGING_SUCCESS,
        res,
    };
};

export const postTextImagingFail = (error) => {
    return {
        type: actionTypes.POST_TEXT_IMAGING_FAIL,
        error: error
    };
};

export const getTextImagingRequest = () => {
    return {
        type: actionTypes.GET_TEXT_IMAGING_REQUEST,
    };
};

export const getTextImagingSuccess = (res) => {
    return {
        type: actionTypes.GET_TEXT_IMAGING_SUCCESS,
        res,
    };
};

export const getTextImagingFail = (error) => {
    return {
        type: actionTypes.GET_TEXT_IMAGING_FAIL,
        error: error
    };
};

export const postTextTaggingRequest = () => {
    return {
        type: actionTypes.POST_TEXT_TAGGING_REQUEST,
    };
};

export const postTextTaggingSuccess = (res) => {
    return {
        type: actionTypes.POST_TEXT_TAGGING_SUCCESS,
        res,
    };
};

export const postTextTaggingFail = (error) => {
    return {
        type: actionTypes.POST_TEXT_TAGGING_FAIL,
        error: error
    };
};

export const getTextTaggingRequest = () => {
    return {
        type: actionTypes.GET_TEXT_TAGGING_REQUEST,
    };
};

export const getTextTaggingSuccess = (res) => {
    return {
        type: actionTypes.GET_TEXT_TAGGING_SUCCESS,
        res,
    };
};

export const getTextTaggingFail = (error) => {
    return {
        type: actionTypes.GET_TEXT_TAGGING_FAIL,
        error: error
    };
};


export const postDocumentLinking = (payload) => {
    return dispatch => {
        dispatch(postDocumentLinkingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('description', payload.description);
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);

        axiosZapier.post( `/4274830/o7dilrb`, fd)
            .then( res => {
                dispatch(postDocumentLinkingSuccess(res));
                window.analytics.track("POST_TEXT_LINKING_SUCCESS", {
                    email: payload.email,
                    type: 'text-linking',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_TEXT_LINKING_SUCCESS",
                    marketing_data: marketingData,
                });
            } )
            .catch( err => {
                dispatch(postDocumentLinkingFail(err));
                window.analytics.track("POST_TEXT_LINKING_FAIL", {
                    email: payload.email,
                    type: 'text-linking',
                    marketing_data: marketingData
                });
            });
    };
};


export const getDocumentLinking = (payload) => {
    return dispatch => {
        dispatch(getDocumentLinkingRequest());
        const fd = new FormData();
        fd.append('text', payload.text);
        fd.append('email', payload.email);

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/61b49c6d6f6cde2d86e4d258e164cdf397ef004d`, fd)
            .then( res => {
                const json  = JSON.parse(res.data.stdout)
                dispatch(getDocumentLinkingSuccess(json.data));
                window.analytics.track("GET_DOCUMENT_LINKING_SUCCESS", {
                    email: payload.email,
                    type: 'document-linking'
                });
            } )
            .catch( err => {
                dispatch(getDocumentLinkingFail(err));
                window.analytics.track("GET_DOCUMENT_LINKING_FAIL", {
                    email: payload.email,
                    type: 'document-linking'
                });
            } );
    };
};

export const postTextImaging = (payload) => {
    return dispatch => {
        dispatch(postTextImagingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('description', payload.description);
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/f89a550c80982a350db71d0f7671129f2e9b002a`, fd)
            .then( res => {
                dispatch(postTextImagingSuccess(res));
                console.log('res', res)
                window.analytics.track("POST_TEXT_IMAGING_SUCCESS", {
                    email: payload.email,
                    type: 'text-imaging',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_TEXT_IMAGING_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(postTextImagingFail(err));
                window.analytics.track("POST_TEXT_IMAGING_FAIL", {
                    email: payload.email,
                    type: 'text-imaging',
                    marketing_data: marketingData
                });
            } );
    };
};

export const getTextImaging = (payload) => {
    return dispatch => {
        dispatch(getTextImagingRequest());
        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('job_key', payload.jobKey);
        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/81a65f6895087e1ed5dd56d145a79c77bb286f18`, fd)
            .then( res => {
                const json  = {
                    image_url: JSON.parse(JSON.parse(res.data.stdout).result).data.image_url ,
                    text: JSON.parse(res.data.stdout).text
                }

                dispatch(getTextImagingSuccess(json));

                window.analytics.track("GET_TEXT_IMAGING_SUCCESS", {
                    email: payload.email,
                    type: 'text-imaging-result'
                });
            } )
            .catch( err => {
                dispatch(getTextImagingFail(err));
                window.analytics.track("GET_TEXT_IMAGING_FAIL", {
                    email: payload.email,
                    type: 'text-imaging-result'
                });
            } );
    };
};

export const postTextTagging = (payload) => {
    return dispatch => {
        dispatch(postTextTaggingRequest());
        const marketingData = payload.marketing_data && JSON.stringify(payload.marketing_data) || {}

        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('algorithm_key', payload.algorithm_key);
        fd.append('texts', JSON.stringify(payload.texts));
        fd.append("context", payload.context);
        fd.append('labels', JSON.stringify(payload.labels));
        fd.append('referrer', payload.referrer);
        fd.append('marketing_data', marketingData);

        axiosWorkflow.post(`/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/9383c4c2254a159a8975b50ea7a10d7439c6d773`, fd)
            .then( res => {
                dispatch(postTextTaggingSuccess(res));
                window.analytics.track("POST_TEXT_TAGGING_SUCCESS", {
                    email: payload.email,
                    type: 'text-tagging',
                    marketing_data: marketingData
                });
                window.analytics.identify(payload.email, {
                    email: payload.email,
                    event: "POST_TEXT_TAGGING_SUCCESS",
                    marketing_data: marketingData
                });
            } )
            .catch( err => {
                console.log('err', err)
                dispatch(postTextTaggingFail(err));
                window.analytics.track("POST_TEXT_TAGGING_FAIL", {
                    email: payload.email,
                    type: 'text-tagging',
                    marketing_data: marketingData
                });
            } );
    };
};

export const getTextTagging = (payload) => {
    return dispatch => {
        dispatch(getTextTaggingRequest());
        const fd = new FormData();
        fd.append('email', payload.email);
        fd.append('job_key', payload.job_key);

        axiosWorkflow.post( `/786243bd688f6789122701a2e9b70f56cabe575e/labs-team/python3.6/128281452f6192a5b1eada40fc41016bde32bce9`, fd)
            .then( res => {
                const json  = res.data;
                dispatch(getTextTaggingSuccess(json));
                
                window.analytics.track("GET_TEXT_TAGGING_SUCCESS", {
                    email: payload.email,
                    type: 'text-tagging'
                });
            } )
            .catch( err => {
                dispatch(getTextTaggingFail(err));
                window.analytics.track("GET_TEXT_TAGGING_FAIL", {
                    email: payload.email,
                    type: 'text-tagging'
                });
            } );
    };
};