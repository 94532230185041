import React from "react"
import styled from "styled-components"
import Form from "./form"
import Result from "./result"

const StyledContainer = styled.div`
  width: unset;
  display: flex;
  flex-direction: column;
  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    width: 52.31rem;
  }
  /* @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
    width: 52.31rem;
  } */
`


const Imaging = (props) => {
  return (
    <StyledContainer
      color="#9F7AEA"
    >
      <Form
        postTextImaging={props.postTextImaging}
        response={props.response}
        params={props.queryParams}
        imagingResult={props.imagingResult}
      />
      {props.queryParams?.key ?
        (
          <Result 
            getTextImaging={props.getTextImaging}
            imagingResult={props.imagingResult}
            jobKey={props.queryParams.key}
            email={props.queryParams.email}
          />
        )
        :
        (
          ''
        )
      }
 
    </StyledContainer>
  )
}


export default Imaging