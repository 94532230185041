import React, {useState, useEffect} from "react"
import styled from "styled-components"


const StyledContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
  line-height: 1.3rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
`

const StyledSectionTitle = styled.div`
  color: #1A212D;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1.5rem;
`

const StyledImage = styled.img`
  border-radius: 0.5rem;
  border: 1px solid rgb(203,213,224);
  margin: auto;
  width: 100%;
  height: auto;
  max-width: 100%;
`

const Imaging = (props) => {
  const imaging = props.imagingResult
  console.log('imaging', imaging)
  return (
    <StyledContainer color="#9F7AEA">
      <StyledSectionTitle>Text Imaging Result</StyledSectionTitle>
      <StyledImage src={imaging.image_url} alt="imaging result" />
    </StyledContainer>
  )
}


export default Imaging
